<template>
  <v-container fluid>
    <v-card tile>

      <v-toolbar>
        <v-btn icon :to="`/admin/${path}`" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Edit payment</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container v-if="loading">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
              Loading data
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-form v-else @submit.prevent="saveItem">
          <v-text-field v-model="item.date" label="DATE"></v-text-field>
          <v-text-field v-model="item.amount" label="AMOUNT"></v-text-field>
          <v-text-field v-model="item.accountNumber" label="ACCOUNT NUMBER"></v-text-field>
          <v-text-field v-model="item.bankCode" label="BANK CODE"></v-text-field>
          <v-text-field v-model="item.variableSymbol" label="VARIABLE SYMBOL"></v-text-field>
          <v-text-field v-model="item.constantSymbol" label="CONSTANT SYMBOL"></v-text-field>
          <v-text-field v-model="item.messageForRecipient" label="MESSAGE FOR RECIPIENT"></v-text-field>
          <v-text-field v-model="item.note" label="NOTE"></v-text-field>
          <v-autocomplete
            v-model="item.user"
            :items="users"
            :item-text="(user) => `UID: ${user.id} - ${user.name} ${user.surname}`"
            :item-value="(user) => user.id"
            return-object
            solo
            label="USER"
          >
          </v-autocomplete>
          <v-btn type="submit" color="success">Save</v-btn>
          <v-btn text @click="$router.push(`/admin/${path}`)">Cancel</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      item: null,
      users: [],
      user: null,
      path: 'payments'
    }
  },
  methods: {
    compare (a, b) {
      return a.id === b.id
    },

    async saveItem () {
      await this.$store.dispatch('rest/updateItem', {
        path: this.path,
        item: this.item
      })
      await this.$router.push(`/admin/${this.path}`)
    }
  },
  async mounted () {
    document.title = 'Edit payment | Masarka Student Club'
    this.loading = true

    this.item = await this.$store.dispatch('rest/fetchItemById', {
      path: this.path,
      id: this.$route.query.id
    })

    this.users = await this.$store.dispatch('rest/fetchAllItems', {
      path: 'users'
    })
    this.loading = false
  }
}
</script>
